import React, { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorFallback } from './ErrorFallback';
import { captureException } from '@sentry/react';
import { Router } from 'next/router';

interface Props {
    children: ReactNode;
    router: Router;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(_error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        captureException(`Uncaught error: ${error}: ${errorInfo}`);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback router={this.props.router} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
