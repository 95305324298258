import { PropsWithChildren } from 'react';

import ErrorPage from '@components/ErrorPage';
import useLocale from '@hooks/useLocale';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import useTranslations from 'context/Translations';
import { Router } from 'next/router';

type Props = PropsWithChildren<{ router: Router }>;

export const ErrorFallback = ({ router }: Props) => {
    const translate = useTranslations();
    const { reset: resetQueryErrors } = useQueryErrorResetBoundary();
    const { appPath } = useLocale();

    const onReset = () => {
        resetQueryErrors();
        if (window.location.pathname === appPath + '/') {
            location.reload();
        } else {
            router.push(appPath);
        }
    };

    return (
        <ErrorPage
            onAction={onReset}
            actionLabel={translate('SESSION_EXPIRED_PROMPT_BUTTON2')}
            title={translate('GENERIC_ERROR_PAGE_TITLE')}
            description={translate('AGENT_LISTS_TOAST_SESSION')}
        />
    );
};
